import React,{useEffect} from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import whiteLabelService from "services/WhiteLabelService";
import './lang'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};




function App() {


  useEffect(() => {

    if(!localStorage.getItem("whiteLabel"))
    {
   
      if (!window.location.host.includes("fomoclips.com") && !window.location.host.includes("localhost")) {
        
        
        const post = { domain_name: window.location.host };
        whiteLabelService
          .getDataFromDomain(post)
          .then((resp) => {
            const whitelabel = resp?.data;
            if(whitelabel)
            {
              localStorage.setItem("whiteLabel", JSON.stringify(whitelabel));
            }
            document.title = whitelabel.brand_name;
            const link = document.createElement("link");
            link.rel = "icon";
            link.href = whitelabel.brand_favicon;
            document.head.appendChild(link);
          })
          .catch(() => {
            document.title = "FomoClips";
          });
      } else {
        document.title = "FomoClips";
      }
    }

  }, []);


  useEffect(() => {
    if(localStorage.getItem("whiteLabel"))
    {

      const whitelabel = JSON.parse(localStorage.getItem("whiteLabel"));
      if(whitelabel)
      {
        document.title = whitelabel.brand_name;
        const link = document.createElement("link");
        link.rel = "icon";
        link.href = whitelabel.brand_favicon;
        document.head.appendChild(link);
      }
     
    }
  }, []);

  
  
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider 
            themeMap={themes} 
            defaultTheme={THEME_CONFIG.currentTheme} 
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>  
      </Provider>
    </div>
  );
}

export default App;
