import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `reset-password`,
        component: React.lazy(() =>import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component:  React.lazy(() => import('views/app-views/profile')),
    },
    {
        key: 'editvideo',
        path: `${APP_PREFIX_PATH}/edit-video`,
        component:  React.lazy(() => import('views/app-views/editVideo')),
    },
    {
        key: 'teams',
        path: `${APP_PREFIX_PATH}/teams`,
        component: React.lazy(() => import('views/app-views/teams')),
    },
    {
        key: 'whitelabel',
        path: `${APP_PREFIX_PATH}/whitelabel`,
        component: React.lazy(() => import('views/app-views/whitelabel'))
    },
    {
        key: 'whitelabelusers',
        path: `${APP_PREFIX_PATH}/whitelabel/users`,
        component: React.lazy(() => import('views/app-views/whitelabel/users'))
    },
    {
        key: 'templates',
        path: `${APP_PREFIX_PATH}/templates`,
        component: React.lazy(() => import('views/app-views/template'))
    },
    {
        key: 'fomolayer',
        path: `${APP_PREFIX_PATH}/add-fomo-layer`,
        component: React.lazy(() => import('views/app-views/addfomo'))
    },
    {
        key: 'videos',
        path: `${APP_PREFIX_PATH}/videos`,
        component: React.lazy(() => import('views/app-views/videos'))
    },
    {
        key: 'brands',
        path: `${APP_PREFIX_PATH}/brands`,
        component: React.lazy(() => import('views/app-views/brands'))
    },
    {
        key: 'publish',
        path: `${APP_PREFIX_PATH}/publish`,
        component: React.lazy(() => import('views/app-views/publish'))
    },
    {
        key: 'bonuses',
        path: `${APP_PREFIX_PATH}/bonuses`,
        component: React.lazy(() => import('views/app-views/bonuses'))
    },
    {
        key: 'bgremover',
        path: `${APP_PREFIX_PATH}/bgremover`,
        component: React.lazy(() => import('views/app-views/bgremover'))
    },{
        key: 'bgremoverlist',
        path: `${APP_PREFIX_PATH}/bgremover/list`,
        component: React.lazy(() => import('views/app-views/bgremover/List'))
    },{
        key: 'purchases',
        path: `${APP_PREFIX_PATH}/purchases`,
        component: React.lazy(() => import('views/app-views/purchases'))
    },{
        key: 'image-editor',
        path: `${APP_PREFIX_PATH}/image-editor`,
        component: React.lazy(() => import('views/app-views/image-editor'))
    },
    {
        key: 'tutorials',
        path: `${APP_PREFIX_PATH}/tutorials`,
        component: React.lazy(() => import('views/app-views/tutorials'))
    }
]